<template>
    <div class="map">
        <div style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>会员地图</div>
        </div>
        <div class="fx_around" style="margin:30px 0px;flex-wrap: wrap;">
            <img src="shexie/img/map.png" width="90%" alt="">
            <div style="display: flex;width: 100%;justify-content: space-between;">
                <div>
                    <el-table :data="data" style="width: 100%" border :header-cell-style="{ background: '#CC0000' }">
                        <el-table-column prop="province_name" label="地区" align="center" width="140px"></el-table-column>
                        <el-table-column prop="num" label="会员人数（人）" width="120px" align="center"></el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-table :data="data1" style="width: 100%" border :header-cell-style="{ background: '#CC0000' }">
                        <el-table-column prop="province_name" label="地区" align="center" width="140px"></el-table-column>
                        <el-table-column prop="num" label="会员人数（人）" width="120px" align="center"></el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-table :data="data2" style="width: 100%" border :header-cell-style="{ background: '#CC0000' }">
                        <el-table-column prop="province_name" label="地区" align="center" width="140px"></el-table-column>
                        <el-table-column prop="num" label="会员人数（人）" width="120px" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { city_user } from '@/api/commit'
export default {
    data() {
        return {
            data: [],
            data1: [],
            data2: []
        };
    },
    created() {
        this.getNumber()
    },
    mounted() {

    },
    methods: {
        getNumber() {
            city_user().then(res => {
                if (res.code == 200) {
                    this.data = res.data.slice(0, 11)
                    this.data1 = res.data.slice(12, 23)
                    this.data2 = res.data.slice(24, 33)

                    console.log(this.data)
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.map {
    ::v-deep .el-table thead {
        color: #fff;
    }

    ::v-deep .el-table__row {
        color: #CC0000;
    }

    ::v-deep .el-table td.el-table__cell,
    ::v-deep .el-table th.el-table__cell.is-leaf,
    ::v-deep .el-table--border,
    .el-table--group {
        border-color: #CC0000;
    }

    ::v-deep .el-table--border::after,
    ::v-deep .el-table--group::after,
    .el-table::before {
        background: #CC0000;
    }
}</style>

